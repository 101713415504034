import assert from 'assert';
import queryString from 'qs';

import ApiClient from '../ApiClient';

// todo change this name to DocumentService when we move fully to the new api
export default class NewDocumentService {
  constructor(apiClient) {
    assert.ok(apiClient instanceof ApiClient, 'apiClient<ApiClient> is required');

    this.apiClient = apiClient;
  }


  fetchDocuments(folderSlug, offset, limit, q) {
    const params = queryString.stringify({ folderSlug, offset, limit, q });
    let requestUrl = `/documents`;
    if (params) {
      requestUrl += `?${params}`;
    }

    return this.apiClient.get(requestUrl);
  }

  fetchDocumentsPage(link) {
    return this.apiClient.get('', { url: link });
  }

  duplicateDocument(documentId, documentName) {
    return this.apiClient.post(`/documents/${documentId}/copy`, { data: { name: documentName } });
  }
}
