import { load } from 'cheerio';
import flatMap from 'lodash/flatMap';
import map from 'lodash/fp/map';
import flow from 'lodash/flow';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import partial from 'lodash/partial';
import includes from 'lodash/includes';
import without from 'lodash/without';
import curry from 'lodash/curry';
import get from 'lodash/get';
import curryRight from 'lodash/curryRight';
import { formatDate, defaultLocale, defaultFormat } from '../date';

// import { CONSTRAIN_TYPE } from '../../Visualization/helpers/constants';  // TODO: Date - We should use CONSTRAIN_TYPE.DATE, but babel got problems for now.

// After making changes in file `run babel thisFineName.js --out-file thisFileName.plain.js`
// and change `const _date = require('../date');` back to `const _date = require('../date/index.plain.js');`
// Prettier will change all vars back to consts
const defaultSettings = {
  language: defaultLocale,
  date_format: defaultFormat,
};

export default function convert(
  html,
  questions,
  answers,
  keepUnansweredQuestions = false,
  styles,
  settings = defaultSettings,
) {
  const $$ = load(html);
  const dataQuestions = $$('[data-question]');
  const handleShowIfQuestions = createHandlerForShowIfQuestions($$, answers, questions, keepUnansweredQuestions);
  const handleInputQuestions = createHandlerForInputQuestions($$, questions, answers, keepUnansweredQuestions, settings);
  const handleAnchorQuestions = createHandlerForAnchorQuestions($$, answers);

  handleShowIfQuestions(dataQuestions);
  handleInputQuestions(dataQuestions);
  handleAnchorQuestions(dataQuestions);
  removeEmptyNodes(['p', 'ul', 'li', 'div'], $$);

  return `<style>${styles}</style><div class='__document_template_dynamic_stylesheet'>${$$('body').html()}</div>`;
}

// ----------- PRIVATE METHODS -----------
function createHandlerForAnchorQuestions($doc, answers) {
  const answeredOptionIds = flatMap(answers, ({ inputId }) => (inputId ? inputId.split(';') : []));
  const linkedQuestionNodeIsRemoved = (optionId) => {
    const linkedQuestion = $doc(`[data-logictype="choiceShowIfType"][data-showif="${optionId}"]`);
    return linkedQuestion.length === 0;
  };

  const handleAnchorType = (q) => {
    const anchorOptionId = $doc(q).attr('data-showif');

    if (linkedQuestionNodeIsRemoved(anchorOptionId)) {
      // if option is not present in document,
      // then it means we should remove that anchor
      return $doc(q).remove();
    }
    if (!isQuestionAnswered(answeredOptionIds, anchorOptionId)) {
      // if option is still in document, but wasnt answered yet,
      // we should add not_ready class to it
      return $doc(q).addClass('not_ready');
    }

    if (isQuestionAnswered(answeredOptionIds, anchorOptionId)) {
      // if option is still in document, and was answered
      // we should remove not_ready class from it
      return $doc(q).removeClass('not_ready');
    }
    console.error('Something went wrong with algorithm inside questionnaireTemplateConverter -> handleAnchorType', { anchorOptionId, answeredOptionIds });
    return q;
  };

  return flow(
    () => $doc('[data-logictype="anchorType"]'),
    map(handleAnchorType),
  );
}
function createHandlerForShowIfQuestions($doc, answers, questions, keepUnansweredQuestions) {
  const answeredOptionIds = flatMap(answers, ({ inputId }) => (inputId ? inputId.split(';') : []));

  const isQuestionAnsweredWithOptions = partial(isQuestionAnswered, answeredOptionIds);
  const getQuestionsAnswers = partial(getAnswerByQuestionId, answers);
  const getOptionIds = partial(getQuestionOptionIds, questions);
  const removeUnusedOptionsFromDoc = partial(
    removeUnusedShowIfOptions,
    $doc,
    isQuestionAnsweredWithOptions,
    getQuestionsAnswers,
    getOptionIds,
    keepUnansweredQuestions,
  );

  return flow(
    () => $doc('[data-logictype="choiceShowIfType"]'),
    map(removeUnusedOptionsFromDoc),
  );
}

function createHandlerForInputQuestions($doc, questions, answers, keepUnansweredQuestions, settings) {
  const getAnswer = partial(getAnswerByOption, answers);
  const setInputQuestionContentInDoc = partial(setInputQuestionContent, $doc, getAnswer, questions, settings);
  const removeInputQuestionInDoc = partial(removeInputQuestion, $doc, getAnswer, keepUnansweredQuestions);


  return flow(
    () => $doc('[data-replace]'),
    map(setInputQuestionContentInDoc),
    map(removeInputQuestionInDoc),
  );
}

function removeEmptyNodes(nodes, $doc) {
  const isEmpty = $el => $el.html().trim() === '' || $el.html() === '<br>';

  $doc(nodes.join(', ')).each((index, el) => {
    const $el = $doc(el);
    const hasBr = el.tagName === 'p' && $el.children('br').length !== 0;
    const isPageBreak = el.tagName === 'p' && $el.hasClass('page-break');
    if (!hasBr && !isPageBreak && isEmpty($el)) {
      $el.remove();
    }
  });
}

function isQuestionAnswered(answeredOptionIds, optionId) {
  return includes(answeredOptionIds, optionId);
}

function getAnswerByQuestionId(answers, question) {
  const questionId = question.attr('data-question-id');
  const found = find(answers, answer => answer.questionId === questionId);


  return found;
}

function getAnswerByOption(answers, optionId, questionId) {
  return find(answers, answer => answer.inputId === optionId)
    || (questionId && find(answers, answer => answer.questionId === questionId));
}

function getQuestionOptionIds(questions, questionId) {
  const question = find(questions, q => q.id === questionId);
  return question.details.options.map(opt => opt.id);
}


function getQuestionDetailsByQuestionId(questions, questionId) {
  const question = find(questions, it => it.id === questionId);

  return question ? question.details : null;
}

function setInputQuestionContent($doc, getAnswer, questions, settings, question) {
  const optionId = $doc(question).attr('data-replace');
  const questionId = $doc(question).attr('data-question-id');
  const questionDetails = getQuestionDetailsByQuestionId(questions, questionId);
  const answer = getAnswer(optionId, questionId);
  let value = get(answer, 'details.value');
  const constrain = get(answer, 'questionConstrain');
  const isDate = constrain === 'DATE'; // TODO: Date - We should use CONSTRAIN_TYPE.DATE, but babel got problems for now.
  const isMultilineInput = constrain === 'NONE' && questionDetails && !!questionDetails.multilineInput;

  if (!value) {
    return question;
  }

  if (isDate) {
    value = formatDate(parseInt(value, 10), settings.date_format, settings.language);
  }

  if (isMultilineInput) {
    // eslint-disable-next-line no-control-regex
    value = value.replace(new RegExp('\n', 'g'), '<br>');
  }

  $doc(question).html(value);
  return question;
}

function removeInputQuestion($doc, getAnswer, keepUnansweredQuestions, question) {
  const $question = $doc(question);
  const optionId = $question.attr('data-replace');
  const questionId = $question.attr('data-question-id');
  const answer = getAnswer(optionId, questionId);

  if (answer && answer.details.value) {
    return question;
  }
  if (keepUnansweredQuestions) {
    $question.addClass('not_ready');
  } else {
    $question.remove();
  }
  return question;
}

function removeUnusedShowIfOptions(
  $doc,
  isAnswered,
  getQuestionAnswer,
  getOptionIds,
  keepUnansweredQuestions,
  question,
) {
  const optionId = $doc(question).attr('data-showif');

  if (!keepUnansweredQuestions && !isAnswered(optionId)) {
    $doc(question).remove();
    return question;
  }

  if (!isAnswered(optionId)) {
    const answer = getQuestionAnswer($doc(question));
    removeUnansweredOptionsForQuestions($doc, answer, getOptionIds, question);

    $doc(question).addClass('not_ready');
  }
  return question;
}

function removeUnansweredOptionsForQuestions($doc, answer, getOptionIds, question) {
  if (answer && answer.inputId) {
    const questionId = $doc(question).attr('data-question-id');
    const questionOptionIds = getOptionIds(questionId);
    const answerOptionIds = answer.inputId.split(';');
    const curriedWithout = curry(without, 2);
    const curriedForEach = curryRight(forEach);

    flow(
      curriedWithout(questionOptionIds),
      curriedForEach(optId => $doc(`[data-showif='${optId}']`).remove()),
    )(...answerOptionIds);
  }
  return question;
}
