import uniqBy from 'lodash/uniqBy';
import cheerio from 'cheerio';
import each from 'lodash/each';
import getOptionsFromQuestions from 'utils/getOptionsFromQuestions';

export default function getFlowFromHtml(html, questions) {
  const options = getOptionsFromQuestions(questions);
  const $ = cheerio.load(html);
  const flow = [];
  const createFlow = (scope = 'body', parent = null) => {
    $(scope)
      .children()
      .each((i, child) => {
        let qRoot = parent || null;
        if (child.attribs['data-question'] && child.attribs['data-logictype'] !== 'anchorType') {
          qRoot = createFlowItem(child, parent);

          if (!qRoot) {
            return;
          }

          if (!parent) {
            flow.push(qRoot);
          } else {
            parent.children.push(qRoot);
          }
        }
        createFlow(child, qRoot);
      });
  };

  function createFlowItem(node, parent) {
    const { attribs } = node;
    const type = attribs['data-showif'] ? 'showif' : 'replace';
    const logictype = attribs['data-logictype'];
    const optionId = type === 'showif' ? attribs['data-showif'] : attribs['data-replace'];
    const question = getQuestionDetails(optionId);

    if (!question) {
      return null;
    }

    const dependsOnOptions = $(node)
      .parents('[data-logictype="anchorType"]')
      .map((i, el) => el.attribs['data-showif'])
      .get();
    return {
      questionId: question.id,
      questionName: question.name,
      type,
      optionId,
      logictype,
      parentOptionId: parent ? parent.optionId : null,
      dependsOnOptions,
      children: [],
      connections: [],
    };
  }

  function getQuestionDetails(optionId) {
    const option = options.find(a => a.id === optionId);
    const question = option && questions.find(q => q.id === option.question_id);

    return question;
  }

  createFlow();
  return applyIndexes(mergeRoots(flow)).newFlow;
}

function applyConnections(flowItem) {
  if (!flowItem.parentOptionId) {
    return flowItem;
  }
  const connection = {
    to: flowItem.questionId,
    from: flowItem.parentOptionId,
  };
  flowItem.connections.push(connection);
  return flowItem;
}

/* eslint-disable */
function mergeRoots(flow) {
  const newFlow = [];
  each(flow, (flowItem) => {
    flowItem = applyConnections(flowItem);

    const found = newFlow.find(el => el.questionId === flowItem.questionId);
    if (found && found.parentOptionId === flowItem.parentOptionId) {
      // if we have already same question on this level,
      // we should merge its options and children

      found.connections = uniqBy([...found.connections, ...flowItem.connections], f => JSON.stringify(f));
      found.children = [...found.children, ...flowItem.children];
      return found;
    }

    return newFlow.push(flowItem);
  });
  each(newFlow, (newFlowItem) => {
    if (newFlowItem.children.length) {
      newFlowItem.children = mergeRoots(newFlowItem.children);
    }
  });
  return newFlow;
}
/* eslint-enable */

/* eslint-disable */
function applyIndexes(flow, index = 1) {
  const newFlow = [];
  each(flow, (item) => {
    item.questionIndex = index;
    index += 1;
    if (item.children.length) {
      const result = applyIndexes(item.children, index);
      item.children = result.newFlow;
      index = result.index;
    }
    newFlow.push(item);
  });
  return { newFlow, index };
}
/* eslint-enable */
